.container {
  text-align: center;
}

#pdf-viewer canvas {
  display: block;
  margin: 0 auto;
  border: 1px solid black;
  margin-bottom: 10px;
}

.pagination {
  margin: 20px;
}

.pagination button {
  padding: 10px;
  margin: 5px;
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  #pdf-viewer canvas {
    width: 100% !important;
    height: auto !important;
  }
}
